<template>
  <b-row class="justify-content-center mt-4 mx-0">
    <b-col class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
      <checkout-summary>
        <template v-if="order.serverFiles.length">
          <div class="my-2" v-for="(item,i) of order.serverFiles">
            <tuned-file-view no-bordered :item="item" :key="i"/>
          </div>
        </template>
      </checkout-summary>
    </b-col>
    <b-col class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
      <b-card header="PAYMENT INFORMATION" body-class="pt-0">
        <template #header>
          <h5 class="text-dark">PAYMENT INFORMATION</h5>
        </template>
        <b-card-body class="p-5">
          <div v-if="previousOrder && applyUpgrade" class="mt-4 payment-line-separator2">
            <b-row style="margin-top: 3%">
              <b-col>
                <b>Previous Order Found</b>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b>Date: </b>
                <br>
                <b>Price: </b>
              </b-col>
              <b-col>
                <span>{{ previousOrder.created_at | localDateShort }}</span>
                <br>
                <span><credits-view :amount="previousOrder.price"
                                    :currency-details="previousOrder.currencyDetails"/></span>
              </b-col>
            </b-row>
          </div>

          <div class="mt-4 mb-4 pt-4 pb-4 payment-line-separator">
            <b-row>
              <b-col>
                <b>Retail price:</b>
              </b-col>
              <b-col>
                <order-price :total="total" use-credits/>
              </b-col>
            </b-row>
            <b-row v-if="total > 0 && total < 9999" class="mt-1 mb-1">
              <b-col>
                <b>Coupon (optional): </b>
                <br>
                <label class="text-success" v-if="coupon.status">
                  <span v-if="!coupon.auto">Coupon successfully validated</span>
                  <span v-else>{{ order.coupon.id }}</span>
                </label>
                <label class="text-danger" v-if="coupon.status === false">Invalid coupon</label>
              </b-col>
              <b-col>
                <input class="form-control text-uppercase" type="text" :disabled="coupon.auto"
                       placeholder="Discount coupon code" @keyup.enter="applyCoupon"
                       @keyup="coupon.code = $event.target.value.toUpperCase()">
                <button class="btn btn-outline-dark btn-block mt-2" @click="applyCoupon()" :disabled="coupon.auto">Apply
                  coupon
                </button>
              </b-col>
            </b-row>
            <b-row v-if="coupon.status" class="mt-4">
              <b-col><b>Discount:</b></b-col>
              <b-col>
                <credits-view :amount="order.coupon.discount"/>
              </b-col>
            </b-row>
            <b-row v-if="applyUpgrade && previousOrder && previousOrder.time_diff < 2.001" class="mt-2">
              <b-col>
                <b>You will get a refund for: </b>
              </b-col>
              <b-col>
                <span><credits-view :amount="previousOrder.price"
                                    :currency-details="previousOrder.currencyDetails"/></span>
              </b-col>
            </b-row>
            <b-row v-if="order.to_refund && order.to_refund.upgrade_fee" class="mt-2">
              <b-col>
                <b>Upgrade fee: </b>
              </b-col>
              <b-col>
                <span><credits-view :amount="order.to_refund.upgrade_fee"
                                    :currency-details="previousOrder.currencyDetails"/></span>
              </b-col>
            </b-row>
            <b-row v-if="previousOrderFound" class="my-3">
              <b-col>
                <div class="text-center text-black-50">
                  <i>Pre-platform order found, TVS could set a custom price</i>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-4" v-if="to_refund.upgrade_fee">
              <b-col><b>Subtotal:</b></b-col>
              <b-col>
                <order-price :total="total" use-credits/>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col><b>Total:</b></b-col>
              <b-col>
                <order-price :total="total + to_refund.upgrade_fee" use-credits/>
              </b-col>
            </b-row>
          </div>
        </b-card-body>
        <b-card-footer class="d-flex justify-content-center">
          <b-col></b-col>
          <b-col class="col-lg-8 col-md-8 col-sm-8">
            <button id="makeRequest" class="btn btn-danger btn-block" @click="save()">PLACE ORDER</button>
          </b-col>
          <b-col></b-col>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {serialize, serializeToDataForm} from "@/utils";
import CheckoutSummary from "@/views/dashboard/checkout/CheckoutSummary";
import TunedFileView from "@/components/SoftwareOrders/TunedFileView";
import CreditsView from "@/components/common/CreditsView";
import OrderPrice from "@/views/dashboard/create-software-order-file/components/OrderPrice";
import {get, post} from "@/services/api";
import {generateRandomInteger} from "@/helpers/random";
import {couponValidate} from "@/services/endpoint.json";
import {encrypt} from "@/helpers/encrypt";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "CheckOut",
  components: {OrderPrice, CreditsView, TunedFileView, CheckoutSummary},
  mixins: [show_alert_mixin],
  data: () => ({
    lastOrder: null,
    previousOrderFound: false,
    oldCoupon: '',
    coupon: {
      code: '',
      auto: false,
      discount: 0,
      status: null, //true, false, null
    },
    to_refund: {
      order_id: null,
      amount: 0,
      upgrade_fee: 0,
    },
  }),
  computed: {
    ...mapState('buySale', ['information']),
    ...mapState('orderSoftwareFile', {
      order: state => state.order,
      VIN: state => state.file_details.VIN,
      stage: state => state.order.stage,
      vehicle: state => state.vehicle,
      previousOrder: state => state.previous_order.to_upgrade,
      pricePolicy: state => state.price_policy,
    }),
    ...mapGetters('orderSoftwareFile', ['total', 'hasCustomPart', 'orderStage', 'orderType', 'subtotal', 'subTotalPlusFees', 'applyUpgrade', 'orderType', 'total', 'filesInDetails']),
    ...mapGetters(['getUserId']),
    ...mapState('geo', ['userCountry', 'userCurrency']),
  },
  methods: {
    ...mapMutations('orderSoftwareFile', ['setCoupon', 'setToRefund']),
    ...mapActions('orderSoftwareFile', ['loadCompletedPreOrder', 'loadPricePolicy']),
    ...mapMutations(['loaderManager']),
    save() {
      this.loaderManager(true);
      const dataToSend = serializeToDataForm({
        orderId: this.getUserId + generateRandomInteger(8),
        type: this.orderType,
        stage: this.orderStage,
        vin: this.VIN,
        description: JSON.stringify({
          description: {
            client: this.order.client,
            ecu_info: this.order.ecu_info,
            to_refund: this.order.to_refund,
            coupon: this.order.coupon,
            vin_verified: this.order.vin_verified,
            note: this.order.note,
          },
          item: this.order.items.map(el => ({
            id: el.id,
            name: el.name,
            type: el.type,
            location: el.location,
            options: el.options,
          })),
        }),
        vehicle: JSON.stringify({...this.vehicle, auto_found: true}),
        tuned_files: JSON.stringify(this.order.serverFiles),
        currencyDetails: {
          country_id: this.userCountry.id,
          locale: this.userCurrency.locale,
          subtotal: this.subTotalPlusFees - (this.order.coupon ? this.order.coupon.discount : 0),
          original_price: 0,
        },
        price: this.total,
        urgency: this.order.urgency,
        files: [...this.order.files, ...this.filesInDetails],
        automated_only: !this.hasCustomPart,
      });
      post('order-software/create', dataToSend, true).then(({data}) => {
        this.$router.push({name: 'order-detail', params: {id: encrypt(JSON.stringify({id: data.order.id}))}})
          .then(() => {
            localStorage.removeItem('cart');
            localStorage.removeItem('admin-software');
            localStorage.removeItem('admin-dsg');
            this.$store.commit('orderSoftware/clear');
            this.$store.commit('orderSoftware/resetFullOrderSoftwareState');
            this.$store.dispatch('orderSoftwareFile/clear');
          });
      })
        .catch(err => {
          this.loaderManager(false);
          if (err.response && err.response.data && err.response.data.message) {
            this.showAlertNotification(err.response.data.message, 'error');
          } else {
            this.showAlertNotification('Something went wrong, please try again later', 'error');
          }
        })
        .finally(() => {
          this.loaderManager(false)
        })
    },
    async applyCoupon() {
      if (this.coupon.code !== '' && (this.oldCoupon !== this.coupon.code)) {
        this.sockets.unsubscribe(`couponUpdated:${this.oldCoupon}`)
        this.oldCoupon = this.coupon.code
        const params = serialize({
          code: this.coupon.code,
          stage: this.orderStage,
          type: this.orderType,
          dsg_family: this.vehicle.dsg_family,
          vehicle: {
            brand: this.vehicle.brand,
            model: this.vehicle.model,
            engine: this.vehicle.engine,
            generation: this.vehicle.generation,
          },
          is_upgrade: this.lastOrder !== null,
        }, '?');
        let {data} = await get(couponValidate, params, true);
        if (data.success) {
          const coupon = {
            id: data.id,
            discount: Number(this.total) * data.percentage,
            percentage: Number(data.percentage),
            only_on_upgrade_fee: data.only_on_upgrade_fee,
            index: data.index,
          };
          this.coupon.status = true;
          this.setCoupon(coupon);
          this.sockets.subscribe(`couponUpdated:${this.coupon}`, () => {
            this.oldCoupon = null;
            this.applyCoupon();
          })
        } else {
          this.coupon.status = false;
        }
      } else if (this.coupon.code === '') {
        this.resetCoupon()
      }
    },
    resetCoupon() {
      this.coupon.status = null;
      this.coupon.code = '';
      this.setCoupon(null)
    },
    checkForUpgrade() {
      if (this.applyUpgrade) {
        const diff = this.previousOrder.time_diff;
        if (diff > 2.001) {
          this.coupon.status = true;
          this.coupon.auto = true;
          const coupon = {
            id: `After 2 years ${this.pricePolicy.discount_after_a_year * 100}% discount`,
            discount: Number(this.total) * this.pricePolicy.discount_after_a_year,
            percentage: Number(this.pricePolicy.discount_after_a_year),
          };
          this.setCoupon(coupon);
        } else {
          //refund previous order
          this.to_refund.order_id = this.previousOrder.id;
          this.to_refund.amount = this.previousOrder.price;
          let diff_price = this.total - this.previousOrder.price;
          if (diff_price < this.pricePolicy.minimum_price) {
            this.to_refund.upgrade_fee = this.pricePolicy.minimum_price - (diff_price > 0 ? diff_price : 0);
          }
          this.setToRefund(this.to_refund);
        }
      }
    },
    findPreviousPlatformOrder() {
      post(`previous-orders/find-last`,
        {vin: this.VIN}, true)
        .then(({data}) => {
          if (data) {
            this.previousOrderFound = data.length > 0;
          }
        });
    },
  },
  mounted() {
    if (!this.VIN) {
      this.$router.push({name: 'order-software-file'});
    }
    window.onbeforeunload = function () {
      return 'This action will erase your order and will make you start over.'
    }
    if (!this.previousOrder) {
      this.findPreviousPlatformOrder();
    }
    this.loadPricePolicy().then(() => this.checkForUpgrade());
  },
  destroyed() {
    window.onbeforeunload = null;
    localStorage.removeItem('cart')
  }
}
</script>

<style scoped>

</style>
