<template>
  <div id="invoice" class="invoice-box">
    <table>
      <tr class="top">
        <td colspan="2">
          <table>
            <tr>
              <td class="title" style="padding-bottom: 0">
                <img src="../../../assets/images/logo.svg" style="width:300px; margin-left: -1vw;">
              </td>

              <td>
                DATE: {{ new Date() | localDateShort }}<br>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr class="information">
        <td colspan="2">
          <table>
            <tr>
              <td>
                <span class="font-weight-bolder">TYPE</span> : <span>{{ orderType }}</span><br>
                <span class="font-weight-bold">URGENCY</span>: <span>{{ order.urgency | urgency }}</span> <br>
              </td>
              <td>
                <span class="font-weight-bold">VIN</span> : <span>{{ file_details.VIN }}</span><br>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr class="heading">
        <td class="white_row">
          DEALER INFORMATION
        </td>
        <td class="white_row">
        </td>
      </tr>
      <tr class="item">
        <td style="padding-top: 0 !important; padding-bottom: 0">
          Name: {{ $store.state.userName }}
        </td>
        <td style="padding-top: 0 !important; padding-bottom: 0">
          Email: {{ $store.state.user.email }}
        </td>
      </tr>
      <tr class="heading">
        <td class="white_row">
          CLIENT INFORMATION
        </td>
        <td class="white_row">
        </td>
      </tr>
      <tr class="item" v-if="order.client">
        <td style="padding-top: 0 !important; padding-bottom: 0">Name:
          {{ order.client.name }}
        </td>
        <td style="padding-top: 0 !important; padding-bottom: 0">Email:
          {{ order.client.mail }}
        </td>
      </tr>
      <template v-if="Object.keys(vehicle).length">
        <tr class="item">
          <td colspan="2" class="text-danger"
              style="padding-top: 0 !important; padding-bottom: 0; word-break: break-all;">
            Notes: {{ order.notes }}
          </td>
        </tr>
        <tr class="heading">
          <td class="white_row">VEHICLE INFORMATION</td>
          <td class="white_row"></td>
        </tr>
        <tr class="item">
          <td style="padding-top: 0 !important; padding-bottom: 0">
            {{ vehicle.brand }} /
            {{ vehicle.model }} /
            {{ vehicle.generation }}
          </td>
          <td style="padding-top: 0 !important; padding-bottom: 0">
            {{ vehicle.engine }} /
            {{ vehicle.dsg|dsgFamily }} /
            {{ vehicle.engine_family }}
          </td>
        </tr>
        <tr class="heading" v-if="order.ecu_info">
          <td class="white_row">ECU TUNE INFORMATION</td>
          <td class="white_row"></td>
        </tr>
        <tr class="item">
          <td v-if="order.ecu_info" colspan="2"
              style="padding-top: 0 !important; padding-bottom: 0">
            <span> Tune: {{order.ecu_info.tune }} </span>
            <span> Power: {{order.ecu_info.power }} </span>
            <span> Fuel: {{order.ecu_info.fuel }} </span>
            <span> Turbo: {{order.ecu_info.turbo}} </span>
            <span> Add: {{order.ecu_info.add}} </span>
          </td>
        </tr>
        <tr class="heading">
          <td class="white_row">
            TYPE OF TUNING
          </td>
          <td class="white_row">
          </td>
        </tr>
        <tr class="item">
          <td style="padding-top: 0 !important; padding-bottom: 0">{{ orderType }}</td>
          <td style="padding-top: 0 !important; padding-bottom: 0">
            {{orderStage | stageType(orderType)}}
          </td>
        </tr>
        <tr style="height: 1vw; border-top: 1px solid black; border-bottom: 1px solid black">
          <td></td>
          <td></td>
        </tr>
        <!--        -------------------------------------------------- ECU HARDWARE ADDITIONAL MODIFICATIONS-->
        <template v-for="{title, values, field} of definitions">
          <tr class="heading" v-if="values.length">
            <td v-b-toggle="`${field}`">{{ title }}</td>
            <td v-b-toggle="`${field}`">
              <i class="fa fa-plus"></i>
            </td>
          </tr>
          <tr v-if="values.length">
            <td colspan="2">
              <b-collapse :id="field" class="mt-2">
                <table style="width: 100%">
                  <tbody>
                  <tr class="" v-for="(item) in values">
                    <td style="padding-top: 0 !important; padding-bottom: 0">{{ item.name }}</td>
                    <td style="padding-top: 0 !important; padding-bottom: 0">
                        <span v-if="item.options.length>0" style="color:black;">
                          {{ item.options[0].description }}
                        </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-collapse>
            </td>
          </tr>
        </template>

      </template>
    </table>
    <slot />
  </div>
</template>

<script>
import {FILE_OPTIONS_DEFINITION} from "@/constants";
import {mapGetters, mapState} from "vuex";

export default {
  name: "CheckoutSummary",
  data: () => ({
    definitions: FILE_OPTIONS_DEFINITION()
  }),
  computed: {
    ...mapState('orderSoftwareFile', {
      file_details: state => state.file_details,
      items: state => state.order.items,
      order: state => state.order,
      vehicle: state => state.vehicle,
    }),
    ...mapGetters('orderSoftwareFile', ['orderType', 'orderStage'])
  },
  methods: {
    setOptions() {
      this.definitions.forEach(item => {
        item.values = this.items.filter(option => {
          return option.location === item.field;
        });
      });
    },
  },
  mounted() {
    this.setOptions();
  },
}
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
